<template>
	<div class="login-main">
		<div class="log-bg"></div>
		<span class="title">云龙平台管理后台</span>
		<div class="login-content">
			<p class="title-text">管理后台</p>
			<div class="name-content flex flex-col-center">
				<img class="input-icon" src="@/assets/login/user_icon.png" alt="" />
				<input class="input-text" v-model="nameText" type="text" placeholder="请输入用户名" />
			</div>
			<div class="name-content flex flex-col-center">
				<img class="input-icon" src="@/assets/login/password_icon.png" alt="" />
				<input class="input-text" v-model="pwdText" type="password" placeholder="请输入登录密码" />
			</div>
			<div class="code-content flex flex-col-center">
				<div class="input-content flex flex-col-center">
					<img class="input-icon" src="@/assets/login/code_icon.png" alt="" />
					<input class="code-input" v-model="codeText" type="text" placeholder="请输入验证码" />
				</div>
				<div class="m-code" @click="getCodeImg">
					<img class="code-img" :src="codeImg" alt="" />
				</div>
			</div>
			<el-button class="load-btn" type="primary" :loading="isLoad" @click="loadClick">登录</el-button>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				nameText: "",
				pwdText: '',
				codeText: '',
				isLoad: false,//是否正在登录
				codeImg: '',//验证码图片
			}
		},
		mounted() {
			this.getCodeImg();
			document.onkeydown = (e)=>{
				var theEvent = window.event || e
				var code = theEvent.keyCode || theEvent.which || theEvent.charCode
				if (code === 13) {
					this.loadClick()
				}
			}
		},
		methods:{
			//登录
			loadClick() {
				if (!this.nameText) return this.$message({type: 'error', message: '请填写用户名！'});
				if (!this.pwdText) return this.$message({type: 'error', message: '请填写密码！'});
				if (!this.codeText) return this.$message({type: 'error', message: '请填写验证码！'});
				this.isLoad = true;
				let data = {
					account: this.nameText,
					password: this.pwdText,
					captcha: this.codeText
				}
				this.$api.getLogin(data).then(res=>{
					this.isLoad = false;
					this.$store.commit('SET_TOKEN', res.data.token);
					this.$store.commit('SET_USER_INFO', res.data);
					this.$router.push({path: '/questionnaire', query: {}})
				}).catch(err=>{
					this.isLoad = false;
					this.getCodeImg();
				})
			},
			//获取验证码图片
			getCodeImg() {
				let math = Math.floor(Math.random()*50);
				this.codeImg = (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/api') + 'admin/captcha?a='+math;
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.login-main{width: 100%;height: 100vh;position: relative;z-index: 0;
		.log-bg{position: absolute;left: 0;top: 0; z-index: -1; width: 100%;height: 100%;background: url('../../assets/login/Login_bg_img.png');  background-size: cover;}
		.title{font-size: 44px;color: #1C72F1;position: absolute;top: 15%;right: 58%;}
		.login-content{position: absolute;right: 20%;top: 15%;background-color: #fff;padding: 60px 40px 118px 40px;width: 440px;box-sizing: border-box;
			.title-text{font-size: 24px;color: #23282D;text-align: center;margin-bottom: 48px;position: relative;
				&:before{position: absolute;content: '';left: 60px;top: 50%;width: 30px;height: 1px;background: #E2E9F3;}
				&::after{position: absolute;content: '';right: 60px;top: 50%;width: 30px;height: 1px;background: #E2E9F3;}
			}
			.name-content{flex: 1;height: 48px;background: #F1F4F7;border-radius: 6px;padding: 0 16px;margin-bottom: 24px;
				.input-icon{width: 16px;height: 16px;margin-right: 10px;}
				.input-text{font-size: 14px;background: none;flex: 1;}
			}
			.code-content{flex:1;margin-bottom: 50px;
				.input-content{height: 48px;background: #F1F4F7;border-radius: 6px;padding: 0 16px;width: 220px;box-sizing: border-box;
					.input-icon{width: 16px;height: 16px;margin-right: 10px;}
					.code-input{font-size: 14px;background: none;flex: 1;}
				}
				.m-code{width: 130px;height: 48px;background: #F1F4F7;border-radius: 6px;margin-left: 10px;overflow: hidden;
					.code-img{width: 100%;height: 100%;}
					&:hover{cursor: pointer;}
				}
			}
			.load-btn{width: 100%;height: 48px;border-radius: 6px;}
		}
		
	}
	
</style>